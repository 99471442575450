
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React, { useEffect, useState } from 'react';
import { APP_NAME, authOptions, FB_PIXEL_ID } from '@apps/config';
import apolloOptions from '@apps/config/apollo.config';
import withSettingsConfig from '@apps/config/HOC/setting.query';
import useThemeConfig from '@apps/config/theme.config';
import ComparisonModal from '@apps/containers/ComparisonModal';
import { WebviewProvider } from '@apps/containers/WebviewProvider/context';
import { DataProvider } from '@apps/context';
import { EvOverviewProvider } from '@apps/context/EvOverviewContext';
import Footer from '@apps/layouts/Footer';
import Navbar from '@apps/layouts/Navbar';
import { LayoutConfigProvider } from '@webbyx/mui';
import { withApolloClient, withAuthIdentity } from '@webbyx/next-js';
import compose from 'lodash/flowRight';
import { NextPage } from 'next';
import { useAmp } from 'next/amp';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Router, useRouter } from 'next/router';
import Script from 'next/script';
import { PagesProgressBar as ProgressBar } from 'next-nprogress-bar';
import { DefaultSeo } from 'next-seo';
import { SEO } from '../../next-seo.config';
// import 'animate.css';
import '@stack/theme/styles/globals.scss';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/plugins/counter.css';
// import 'react-perfect-scrollbar/dist/css/styles.css';
type ExtendedAppProps = AppProps & {
    Component: NextPage;
};
/**
 * ===========================
 * MAIN
 * ===========================
 */
const MyApp = (props: ExtendedAppProps) => {
    const { Component, pageProps } = props;
    // ======================= HOOKS
    const router = useRouter();
    // ======================= VARIABLES
    const webview = router.pathname.startsWith('/webview') ?? false;
    const searchbar = webview;
    const config = useThemeConfig();
    const layout = Component?.layout || 'default';
    const bodyScroll = Component?.bodyScroll || 'scrollable';
    const unScrollableBody = bodyScroll !== 'scrollable';
    const setPageThemeSettings = Component.setPageThemeSettings ?? undefined;
    // ======================= STATE
    const [loading, setLoading] = useState(false);
    const siteUrl = process.env.NEXT_PUBLIC_BASE_URL || 'https://www.carz.com.my';
    // ======================= EFFECTS
    useEffect(() => {
        const start = () => {
            setLoading(true);
        };
        const end = () => {
            setLoading(false);
        };
        Router.events.on('routeChangeStart', start);
        Router.events.on('routeChangeComplete', end);
        Router.events.on('routeChangeError', end);
        return () => {
            Router.events.off('routeChangeStart', start);
            Router.events.off('routeChangeComplete', end);
            Router.events.off('routeChangeError', end);
        };
    }, []);
    useEffect(() => {
        if (loading || unScrollableBody) {
            document.body.style.overflow = 'hidden';
        }
        else
            document.body.style.overflow = 'scroll';
        return () => { };
    }, [loading, unScrollableBody]);
    const isAmp = useAmp();
    // ======================= VIEWS
    const renderLayoutContent = () => {
        if (layout === 'none') {
            return (<DataProvider>
          <EvOverviewProvider>
            <WebviewProvider>
              <Component {...pageProps} searchbar={searchbar} webView={webview}/>
              <ComparisonModal />
            </WebviewProvider>
          </EvOverviewProvider>
        </DataProvider>);
        }
        if (layout === 'customContainer') {
            return (<DataProvider>
          <WebviewProvider>
            <Navbar pageProps={pageProps} webView={webview}/>
            {!isAmp && (<ProgressBar height="3px" color="#31acbf" options={{ showSpinner: false }} shallowRouting/>)}
            <main>
              <Component {...pageProps} searchbar={searchbar} webView={webview}/>
            </main>
            {!webview && <Footer pageProps={pageProps} webView={webview}/>}
            <ComparisonModal />
          </WebviewProvider>
        </DataProvider>);
        }
        return (<DataProvider>
        <EvOverviewProvider>
          <WebviewProvider>
            <Navbar pageProps={pageProps} webView={webview}/>
            {!isAmp && (<ProgressBar height="3px" color="#31acbf" options={{ showSpinner: false }} shallowRouting/>)}
            <main className="body-wrapper">
              <Component {...pageProps} searchbar={searchbar} webView={webview}/>
            </main>
            {!webview && <Footer pageProps={pageProps} webView={webview}/>}
            <ComparisonModal />
          </WebviewProvider>
        </EvOverviewProvider>
      </DataProvider>);
    };
    return (<>
      <DefaultSeo {...SEO} dangerouslySetAllPagesToNoFollow dangerouslySetAllPagesToNoIndex/>
      {/* <WebPageJsonLd
          id={`${BASE_URL}`}
          url={`${BASE_URL}`}
          name="CariCarz Malaysia"
          description="Search for new cars, used cars & recon cars on CariCarz.com! - an up & coming online car buying site in Malaysia. Find your next car and stay up-to-date with the latest news, launches, car prices, and more!"
        />
        <OrganizationJsonLd
          type={'Organization'}
          id={`${BASE_URL}`}
          url={`${BASE_URL}`}
          name="CariCarz Malaysia"
          alternateName={[
            'CariCarz Sdn Bhd',
            'CariCarz',
            'Cari Carz',
            'caricarz.com',
          ]}
          description="Search for new cars, used cars & recon cars on CariCarz.com! - an up & coming online car buying site in Malaysia. Find your next car and stay up-to-date with the latest news, launches, car prices, and more!"
          disambiguatingDescription="Malaysia Online Car Buying Site"
          slogan="Everything you need in one place. Car buying at its easiest."
          logo="/caricarz-logo.png"
          address={{
            type: 'PostalAddress',
            streetAddress:
              '3-1, Jalan Merbah 1, Bandar Puchong Jaya, 47170 Puchong, Selangor',
            addressLocality: 'MYS',
            addressRegion: 'Selangor',
            postalCode: '47170',
            addressCountry: 'Malaysia',
          }}
          telephone={'+6016-2666962'}
          contactPoint={[
            {
              telephone: '+6016-2666962',
              contactType: 'Customer Service',
              areaServed: 'MALAYSIA',
              availableLanguage: ['English', 'Chinese', 'Malay'],
            },
          ]}
          sameAs={[
            'https://www.caricarz.com/',
            'https://www.facebook.com/caricarz.my',
            'https://www.linkedin.com/company/caricarz/',
            'https://www.instagram.com/caricarz/',
            'https://www.pinterest.com/officialcaricarz/_saved/',
            'https://www.youtube.com/channel/UC5BqQOXc1NbPKmM0zFJsZjA',
          ]}
        />
        <SiteLinksSearchBoxJsonLd
          type="WebSite"
          url={`${BASE_URL}`}
          potentialActions={[
            {
              target: `${BASE_URL}/search/?q`,
              queryInput: 'search_term_string',
            },
          ]}
        /> */}
      <Script id="facebook-pixel" strategy="lazyOnload" dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${FB_PIXEL_ID});
            fbq('track', 'PageView');
          `
        }}/>
      {/* <Script
          strategy="lazyOnload"
          src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_KEY}`}
        />
        <Script id="google-analytics" strategy="lazyOnload">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GOOGLE_ANALYTICS_KEY}', {
            page_path: window.location.pathname,
            });
        `}
        </Script> */}
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1 maximum-scale=1"/>
        <meta name="apple-mobile-web-app-capable" content="yes"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="black"/>
        <meta name="twitter:title" content={'Malaysia Automotive News: Latest, Car Launches, Reviews & Insights | Carz Automedia Malaysia'}/>
        <meta name="twitter:description" content={'Search for new cars, used cars & recon cars on Carz.com.my! - an up & coming online car buying site in Malaysia. Find your next car and stay up-to-date with the latest news, launches, car prices, and more!'}/>
        <meta name="twitter:image" content={`${siteUrl}/carz-automedia-logo.png`}/>
        <meta name="twitter:image:type" content="image/png"/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:url" content={siteUrl}/>
        <title>{APP_NAME} | Carz Automedia Malaysia</title>
      </Head>
      <div>
        {!isAmp ? (<>
            <LayoutConfigProvider config={config} pageSettings={setPageThemeSettings?.()} paths={[router.asPath, router.pathname]}>
              {renderLayoutContent()}
            </LayoutConfigProvider>
          </>) : (renderLayoutContent())}
      </div>
    </>);
};
const __Next_Translate__Page__19487dcf4b1__ = compose(withSettingsConfig(), withApolloClient({ ssr: true, options: apolloOptions }), withAuthIdentity({ ssr: true, apollo: true, options: authOptions }))(MyApp);

    export default __appWithI18n(__Next_Translate__Page__19487dcf4b1__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  